import React from 'react';
import { Field } from 'formik';

import { ActiveTypeOption } from '../../config/activeTypes';
import { InputUI, DatePickerUI, AutoCompleteSelectUI, FormLabelUI, FormErrorUI, SelectUI } from '../Interface';

export const FormFields = ({
  viewField,
  submenuId,
  selectedValues,
  values,
  validateField,
  setFieldValue,
  errors,
  params,
}) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-3 mx-4 mb-5">
      <div className="col-span-4 md:col-span-3">
        <Field
          type="text"
          name="projectName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Projekt neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.projectName} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.projectName !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="orderNumber"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Rendelési szám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.orderNumber} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.orderNumber !== '' }}
        />
      </div>
      <div className={selectedValues.generatedName ? 'col-span-4' : 'hidden'}>
        <Field
          type="text"
          name="generatedName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Összefűzött projekt név" />}
          variant="standard"
          disabled
          InputLabelProps={{ shrink: values.generatedName !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="date"
          name="projectStartDate"
          component={DatePickerUI}
          value={values.projectStartDate}
          fullWidth
          label={<FormLabelUI text="Kezdő dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.projectStartDate} />}
          onChange={(date) => {
            setFieldValue('projectStartDate', date).then(() => {
              validateField('projectStartDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.projectStartDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="date"
          name="projectEndDate"
          component={DatePickerUI}
          value={values.projectEndDate}
          fullWidth
          label={<FormLabelUI text="Befejező dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.projectEndDate} />}
          onChange={(date) => {
            setFieldValue('projectEndDate', date).then(() => {
              validateField('projectEndDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.projectEndDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="companyId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Cég" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.companyId} />}
          onChange={(_e, newVal) => {
            setFieldValue('companyId', newVal?.value ?? null).then(() => {
              validateField('companyId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.companyId !== '' }}
          selectedValue={values.companyId}
          selectedLabelValue={selectedValues?.company?.companyName}
          table={'company'}
          listType={{ id: 'companyId', name: 'companyName' }}
          conditions={`company.archive='N'`}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="statusId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Státusz" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.statusId} />}
          onChange={(_e, newVal) => {
            setFieldValue('statusId', newVal?.value ?? null).then(() => {
              validateField('statusId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.statusId !== '' }}
          selectedValue={values.statusId}
          selectedLabelValue={selectedValues?.status?.itemName}
          table={['statusitems', 'status']}
          listType={{ id: 'itemId', name: 'itemName' }}
          joins={'statusitems.statusId = status.statusId'}
          conditions={[`statusitems.archive = 'N'`, `status.subMenuId = ${submenuId}`]}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="active"
          component={SelectUI}
          fullWidth
          label={<FormLabelUI text="Aktív" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.active} />}
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('active', value ?? null).then(() => {
              validateField('active');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.active !== '' }}
          option={ActiveTypeOption}
          value={values.active ?? ''}
        />
      </div>
      {params.PROVINCE_FIELD_AND_TAB === 'Y' && (
        <div className="col-span-4 md:col-span-1">
          <Field
            type="autocomplete"
            name="provinceId"
            component={AutoCompleteSelectUI}
            fullWidth
            label={<FormLabelUI text="Tartomány" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.provinceId} />}
            onChange={(_e, newVal) => {
              setFieldValue('provinceId', newVal?.value ?? null).then(() => {
                validateField('provinceId');
              });
            }}
            disabled={viewField}
            InputLabelProps={{ shrink: values.provinceId !== '' }}
            selectedValue={values.provinceId}
            selectedLabelValue={selectedValues?.province?.name}
            table={['province']}
            listType={{ id: 'id', name: 'name' }}
            conditions={[`province.deletedAt IS NULL AND province.deletedBy IS NULL`]}
          />
        </div>
      )}
    </div>
  );
};
