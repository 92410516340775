import { useState, useEffect } from 'react';
import _ from 'lodash';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import projectService from '../../service/project.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';

import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI } from '../Interface';
import { FormFields } from './FormFields';
import useUsers from '../../context/UsersContext';
import FormTabs from './FormTabs';
import { getDynVals } from '../../utils/DynHelper';

export const ProjectForm = ({ projectState, infoPanel, dialog }) => {
  const {
    setRefetchTable,
    selectedValues,
    setSelectedValues,
    viewField,
    setViewField,
    operatorsDisabled,
    setOperatorsDisabled,
    submenu,
    submenuNames,
    params,
    canEdit,
    setRowSelectionModel,
  } = projectState;
  const { user, getUser } = useUsers();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [dynProps, setDynProps] = useState([]);

  const [defaultValues, setDefaultValues] = useState({});
  const [initValues, setInitValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [resourceList, setResourceList] = useState({
    resourceOne: [],
    resourceTwo: [],
    resourceThree: [],
    resourceFour: [],
    resourceFive: [],
  });
  const [contactList, setContactList] = useState([]);
  const [communicationList, setCommunicationList] = useState([]);

  useEffect(() => {
    getUser();

    const def = {
      projectId: null,
      projectName: '',
      companyId: null,
      statusId: null,
      projectStartDate: dayjs(new Date()),
      projectEndDate: dayjs(new Date()),
      projectPrepare: '',
      userId: user, //@TODO legyeb disabled szerintem? Kérdezzük meg Attilát
      projectDescription: '',
      projectGroupId: null,
      orderNumber: '',
      active: true,
      provinceId: null,
      generatedName: '',
      dynamicFields: {},
    };

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
        setResourceList((prev) => ({
          ...prev,
          resourceOne: selectedValues.projectResourceOne ?? [],
          resourceTwo: selectedValues.projectResourceTwo ?? [],
          resourceThree: selectedValues.projectResourceThree ?? [],
          resourceFour: selectedValues.projectResourceFour ?? [],
          resourceFive: selectedValues.projectResourceFive ?? [],
        }));
        setContactList(selectedValues.projectContact ?? []);
        setCommunicationList(selectedValues.projectCommunication ?? []);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  useEffect(() => {
    setDefaultValues((prev) => {
      return { ...prev, userId: user.userId };
    });

    setDefaultSelectedValues((prev) => {
      return {
        ...prev,
        user: user,
      };
    });
  }, [user]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  const validationSchema = Yup.object().shape({
    projectId: Yup.string().nullable(),
    projectName: Yup.string().required(),
    companyId: Yup.string().nullable(),
    statusId: Yup.string().nullable(),
    projectStartDate: Yup.string().required(),
    projectEndDate: Yup.string().required(),
    projectPrepare: Yup.number().nullable().positive(),
    userId: Yup.string().required(),
    projectDescription: Yup.string().max(1000),
    projectGroupId: Yup.string().nullable(),
    orderNumber: Yup.string().nullable(),
    active: Yup.string().nullable(),
    provinceId: Yup.string().nullable(),
  });

  const valuesToForm = () => {
    const {
      projectId,
      projectName,
      companyId,
      statusId,
      projectStartDate,
      projectEndDate,
      projectPrepare,
      userId,
      projectDescription,
      projectGroupId,
      orderNumber,
      active,
      provinceId,
      generatedName,
    } = selectedValues;

    setInitValues({
      projectId,
      projectName,
      companyId,
      statusId,
      projectStartDate: dayjs(projectStartDate),
      projectEndDate: dayjs(projectEndDate),
      projectPrepare: projectPrepare ?? defaultValues.projectPrepare,
      userId,
      projectDescription,
      projectGroupId,
      orderNumber: orderNumber ?? defaultValues.orderNumber,
      active,
      provinceId,
      generatedName,
      dynamicFields: getDynVals(dynProps, selectedValues.dynamicProperties),
    });
  };

  const handleSubmit = (values) => {
    const projectResourceOne = resourceList.resourceOne || [];
    const projectResourceTwo = resourceList.resourceTwo || [];

    if (params.PROJECT_WARNINGS === 'Y') {
      if (projectResourceOne.length > 0) {
        const typeFourCount = projectResourceOne.filter(
          (resource) => resource.resourceOne?.type?.itemName === '4'
        ).length;

        if (typeFourCount < 2) {
          addNotification({
            content: 'A projekt nem tartalmaz két 4-es típusú dolgozót!',
            type: NotificationType.WARNING,
          });
        }
      }

      if (projectResourceOne.length > 0 && projectResourceTwo.length === 0) {
        addNotification({
          content: 'A projekt nem tartalmaz autókat!',
          type: NotificationType.WARNING,
        });
      }
    }

    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      projectPrepare: parseInt(values.projectPrepare),
      projectStartDate: dayjs(values.projectStartDate).toISOString(),
      projectEndDate: dayjs(values.projectEndDate).toISOString(),
      formId: submenu,
      lineId: selectedValues.projectId,
      updatedBy: user.userId,
    };

    projectService.updateProject(updData, selectedValues.projectId).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);

      if (dialog) {
        dialog.setTaskChanged(true);
        dialog.handleClose();
      }
    });
  };

  const handleInsertObject = async (values) => {
    const formatResources = (resourceArray, includeLeader = false) => {
      return resourceArray
        ? resourceArray.map((resource) => {
            const formattedResource = { resourceId: resource.resourceId };
            if (includeLeader && resource.leader) {
              formattedResource.leader = resource.leader;
            }
            return formattedResource;
          })
        : [];
    };

    const insertObject = {
      ...values,
      projectPrepare: parseInt(values.projectPrepare),
      projectStartDate: dayjs(values.projectStartDate).toISOString(),
      projectEndDate: dayjs(values.projectEndDate).toISOString(),
      formId: submenu,
      projectResourceOne: formatResources(selectedValues?.projectResourceOne, true),
      projectResourceTwo: formatResources(selectedValues?.projectResourceTwo),
      projectResourceThree: formatResources(selectedValues?.projectResourceThree),
      projectResourceFour: formatResources(selectedValues?.projectResourceFour),
      projectResourceFive: formatResources(selectedValues?.projectResourceFive),
      projectContact: selectedValues?.projectContact,
      createdBy: user.userId,
    };

    projectService
      .createProject(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });

        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setSelectedValues(data);

        setRowSelectionModel(data.projectId);

        if (dialog) {
          dialog.setTaskChanged(true);
          dialog.handleClose();
        }
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    projectService.deleteProject(deleteData, selectedValues.projectId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultValues);
    });
  };

  const onAddClick = () => {
    setSelectedValues(defaultSelectedValues);
    setInitValues(defaultValues);
    setViewField(false);
    setAddField(false);
    setContactList([]);
    setCommunicationList([]);
    setResourceList({
      resourceOne: [],
      resourceTwo: [],
      resourceThree: [],
      resourceFour: [],
      resourceFive: [],
    });
  };

  const onModifyClick = () => {
    setViewField(false);
    setEditButtonvisible(false);
  };

  const onDeleteClick = () => {
    setShowDeleteDialog(true);
  };

  const onCopyClick = () => {
    setViewField(false);
    setAddField(false);
  };

  return (
    <>
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white sm:rounded-lg">
        <div className="h-full overflow-auto custom-scrollbar">
          <FormHeaderUI
            addClick={!infoPanel && !dialog ? onAddClick : null}
            addDisabled={!viewField || !canEdit}
            modClick={!infoPanel ? onModifyClick : null}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={
              (typeof params.PROJECT_DELETE_BUTTON === 'undefined' || params.PROJECT_DELETE_BUTTON === 'Y') &&
              !infoPanel
                ? onDeleteClick
                : null
            }
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={!infoPanel ? onCopyClick : null}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
            //validateOnMount={false}
          >
            {({ values, setFieldValue, errors, validateField, resetForm }) => (
              <Form>
                <FormFields
                  viewField={viewField}
                  selectedValues={selectedValues}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  submenuId={submenu}
                  validateField={validateField}
                  params={params}
                />

                <FormTabs
                  disabled={viewField}
                  submenuId={submenu}
                  addField={addField}
                  setFieldValue={setFieldValue}
                  validateField={validateField}
                  values={values}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  resourceList={resourceList}
                  setResourceList={setResourceList}
                  setRefetchTable={setRefetchTable}
                  user={user}
                  submenuNames={submenuNames}
                  errors={errors}
                  params={params}
                  contact={{ contactList, setContactList }}
                  communication={{ communicationList, setCommunicationList }}
                  canEdit={canEdit}
                  setDefaultValues={setDefaultValues}
                  dynProperties={{ dynProps, setDynProps }}
                  infoPanel={infoPanel}
                />

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
