import { useEffect, useState } from 'react';
import { ButtonUI, CheckboxUI, DatePickerUI, DialogUI, FormLabelUI } from '../Interface';
import dayjs from 'dayjs';

export const ActiveTimeDialog = ({ open, handleClose, date, active, saveView, ganttFilter }) => {
  const { dateRange, setDateRange } = date;
  const { yearViewActiveTime, setYearViewActiveTime } = active;
  const [localDateRange, setLocalDateRange] = useState({});

  const [localActiveTime, setLocalActiveTime] = useState(false);

  useEffect(() => {
    setLocalActiveTime(yearViewActiveTime);
  }, [yearViewActiveTime]);

  useEffect(() => {
    if (
      Object.keys(ganttFilter).length > 0 &&
      ganttFilter?.activeTimeDate?.startDate &&
      ganttFilter?.activeTimeDate?.endDate
    ) {
      setLocalDateRange({
        startDate: dayjs(ganttFilter?.activeTimeDate?.startDate),
        endDate: dayjs(ganttFilter?.activeTimeDate?.endDate),
      });
    }
  }, [ganttFilter]);

  const handleDateChange = (newDate, fieldName) => {
    setLocalDateRange((prevRange) => ({
      ...prevRange,
      [fieldName]: newDate,
    }));
  };

  const handleSave = () => {
    setDateRange(localDateRange);
    setYearViewActiveTime(localActiveTime);
    saveView('ganttFilter', {
      yearViewActiveTime: localActiveTime,
      activeTimeDate: localDateRange,
    });
    handleClose();
  };

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent="Aktív idő"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <div className="mt-5">
        <div className="text-labelColor">
          <div className="mt-5">
            <div className="flex justify-center gap-5">
              <div className="col-span-4 md:col-span-2 ">
                <DatePickerUI
                  type="date"
                  name="startDate"
                  value={dayjs(localDateRange.startDate)}
                  onChange={(newDate) => handleDateChange(newDate, 'startDate')}
                  fullWidth
                  label={<FormLabelUI text="Kezdő dátum" />}
                  variant="standard"
                />
              </div>
              <div className="col-span-4 md:col-span-2">
                <DatePickerUI
                  type="date"
                  name="endDate"
                  value={dayjs(localDateRange.endDate)}
                  onChange={(newDate) => handleDateChange(newDate, 'endDate')}
                  fullWidth
                  label={<FormLabelUI text="Befejező dátum" />}
                  variant="standard"
                />
              </div>
            </div>
            <div className="text-center">
              <CheckboxUI
                label="Dátumok használata"
                checked={localActiveTime}
                onChange={(e) => setLocalActiveTime(e.target.checked)}
              />
            </div>
            <div className="flex justify-center gap-5 mt-5">
              <ButtonUI text="Bezárás" color="blue-gray" variant="gradient" onClick={handleClose} />
              <ButtonUI text="Mentés" className="bg-success" onClick={handleSave} />
            </div>
          </div>
        </div>
      </div>
    </DialogUI>
  );
};
