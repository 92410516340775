import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import _ from 'lodash';
import dayjs from 'dayjs';
import Yup from '../../../utils/yup';
import utc from 'dayjs/plugin/utc';

import addNotification from '../../../utils/addNotification';
import { NotificationType } from '../../../config';
import useMenus from '../../../context/MenuContext';
import masterDataService from '../../../service/masterData.service';
import useUsers from '../../../context/UsersContext';
import resourceOneService from '../../../service/resources/resourceOne.service';
import { getDynVals } from '../../../utils/DynHelper';
import propertyService from '../../../service/property.service';

import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI } from '../../Interface';
import ResourceOneReports from './ResourceOneReports';
import { ResourceOneFields } from './ResourceOneFields';
import ResoureOneTabs from './ResoureOneTabs';

export const ResourceOnePage = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getUser, user } = useUsers();
  const [refetchTable, setRefetchTable] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [dynProps, setDynProps] = useState([]);

  const [defaultValues, setDefaultValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [autocompleteData, setAutocompleteData] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [submenu, setSubmenu] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [communicationList, setCommunicationList] = useState([]);
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    classification: [],
  });
  const [propertyGroups, setPropertyGroups] = useState([]);
  const [group, setGroup] = useState({
    id: [],
  });

  useEffect(() => {
    getMenus();
    getUser();

    const def = {
      name: '',
      groupId: null,
      active: 1,
      statusId: null,
      typeId: null,
      generatedName: '',
      classificationId: null,
    };

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (submenu) {
      getClassifications();
    }
  }, [submenu]);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          statusId: autocompleteData?.status?.defaultValue ?? null,
          typeId: autocompleteData?.type?.defaultValue ?? null,
          groupId: autocompleteData?.resourceGroup?.defaultValue ?? null,
          classificationId: autocompleteData?.classification?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          status: autocompleteData?.status?.defItem,
          type: autocompleteData?.type?.defItem,
          resourceGroup: autocompleteData?.resourceGroup?.defItem,
          classification: autocompleteData?.classification?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('resourceOne');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (submenu) {
      initDefaultValues();
      getPropertyGroupsForForm();
    }
  }, [submenu]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
        setCommunicationList(selectedValues.resourceOneCommunication ?? []);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const getPropertyGroupsForForm = () => {
    propertyService.getPropertyGroupsForSubmenus({ data: [submenu] }).then((data) => {
      setPropertyGroups(data);
    });
  };

  const getClassifications = () => {
    resourceOneService.getClassificationBySubMenuId(submenu).then((data) => {
      const classificationItems = data.flatMap((item) => item.classificationItem);

      const formattedItems = classificationItems.map((item) => ({
        label: `${item.name}`,
        value: item.id,
      }));

      setAutoCompleteOptions((prevState) => ({
        ...prevState,
        classification: formattedItems,
      }));
    });
  };

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .typeToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .statusToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .groupToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .classificationToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          type: resp[0],
          status: resp[1],
          resourceGroup: resp[2],
          classification: resp[3],
        };
        setAutocompleteData(aData);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    groupId: Yup.string().nullable(),
    active: Yup.number().nullable(),
    statusId: Yup.string().nullable(),
    typeId: Yup.string().nullable(),
    classificationId: Yup.string().nullable(),
  });

  const valuesToForm = () => {
    const { name, groupId, active, statusId, typeId, generatedName, classificationId } = selectedValues;

    setInitValues({
      name,
      groupId,
      active,
      statusId,
      typeId,
      generatedName,
      classificationId,
      dynamicFields: getDynVals(dynProps, selectedValues.dynamicProperties),
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    resourceOneService.updateResource(updData, selectedValues.id).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    resourceOneService
      .createResource(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });

        setSelectedValues(data);

        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    resourceOneService.deleteResource(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultSelectedValues);
    });
  };

  const handleTemplate = () => {
    resourceOneService.getExcelTemplate().then(() => {
      addNotification({
        content: 'A letöltés elindult!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  const handleExport = () => {
    resourceOneService.getExcelData().then(() => {
      addNotification({
        content: 'A letöltés elindult!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  const handleImport = (files) => {
    resourceOneService.importExcel(files, user.userId).then(() => {
      setRefetchTable(true);
      addNotification({
        content: 'Az import sikeres!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem]">
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="h-full overflow-auto custom-scrollbar">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues(defaultSelectedValues);
              setViewField(false);
              setAddField(false);
            }}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue, errors, validateField, resetForm }) => (
              <Form>
                <ResourceOneFields
                  viewField={viewField}
                  submenuId={submenu}
                  selectedValues={selectedValues}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  validateField={validateField}
                  autocompleteOptions={autocompleteOptions}
                />
                <ResoureOneTabs
                  disabled={viewField}
                  submenuId={submenu}
                  setFieldValue={setFieldValue}
                  validateField={validateField}
                  selectedValues={selectedValues}
                  setDefaultValues={setDefaultValues}
                  dynProperties={{ dynProps, setDynProps }}
                  communication={{ communicationList, setCommunicationList }}
                  user={user}
                  setRefetchTable={setRefetchTable}
                  handleImport={handleImport}
                  handleExport={handleExport}
                  handleTemplate={handleTemplate}
                  propertyGroups={propertyGroups}
                  groupState={{ group, setGroup }}
                />
                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);

                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg  ">
        <div className="h-full overflow-auto custom-scrollbar">
          <ResourceOneReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
            submenuId={submenu}
            rowSelection={{ rowSelectionModel, setRowSelectionModel }}
            autocompleteOptions={autocompleteOptions}
          />
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </div>
  );
};
